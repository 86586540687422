.message{
    margin: 10px;
    margin-left: 0;
}
.text{
    border-radius: 12px;
    border-width: 2px;
    border-style: solid;
    padding: 5px;
}
.textDiv{
    margin: 7px;
    margin-bottom: 13px;
}
#chat{
    margin-top: 30px;
    border-width: 4px;
    padding: 15px;
    background-color: rgb(140, 171, 202);
    border-radius: 10px;
}
#chatbox{
    border-radius: 5px;
    background-color: lightsteelblue;
    padding: 10px;
    margin: 5px;

}